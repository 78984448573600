.ViewContact h1 {
  font-size: 28px;
  line-height: 31px;
  color: #0b2e53;
}

.ViewContact h3 {
  font-size: 18px;
  line-height: 33px;
  color: #0b2e53;
  margin: 0px;
  margin-bottom: 20px;
}

.ViewContact p {
  position: relative;
  bottom: 8px;
  white-space: pre-wrap;
}

.ViewContact ul {
  position: relative;
  list-style-type: none;
  padding-left: 0px;
  margin-top: 40px;
}

.ViewContact li::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #FF10A2;
  transform: translateX(-50%);
}

.ViewContact li {
  list-style: none;
  width: 100%;
  position: relative;
  border-left: 1px solid #FF10A2;
  padding-left: 1.5rem;
  min-height: auto;
  padding-bottom: 2.5rem;
}

.ViewContact .highlight {
  color: #FF10A2 !important;
  font-weight: 600;
  opacity: 1;
}

.ViewContact p {
  opacity: 1;
}
