@font-face {
  font-family: "TradeGothic";
  src: local("TradeGothic"),
    url("../Fonts/trade-gothic-bold.woff") format("woff");
}

.Home {
  background-color: #fff;
}

.Home .hero {
  background-image: url("https://conversify-marketing.s3.amazonaws.com/conversify-background.png");
  background-size: cover;
  padding: 150px 0px;
  position: relative;
  bottom: 70px;
}

.Home .hero h1 {
  font-weight: 800;
  font-size: 36px;
  line-height: 50px;
  color: #fff;
  font-family: "TradeGothic", sans-serif;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.Home .hero .tagline {
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 30px;
}

.Home .hero .secondary-btn {
  margin-top: 30px;
  padding: 20px;
  display: block;
  max-width: 300px;
  margin: auto;
  border: 5px solid #fff;
  opacity: 1;
  background-color: #FF10A2;
}

.intro h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  font-family: "TradeGothic", sans-serif;
  line-height: 36px;
  padding-top: 80px;
  text-transform: uppercase;
}

.intro p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
}

.remote {
  padding: 120px 0px;
  padding-top: 0px;
}

.remote h3 {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 35px;
  font-family: "TradeGothic", sans-serif;
  text-transform: uppercase;
}

.remote-intro {
  padding-right: 50px;
}
.remote-intro button {
  margin-top: 30px;
}

.remote p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 20px;
}

.learning {
  text-align: center;
}

.learning h5 {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
}

.learning p {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
}

.learning img {
  height: 30px;
  margin-top: 10px;
}

.about-me {
  background-color: #efefef;
  padding: 80px;
}

.about-me img {
  height: 170px;
  border-radius: 15px;
}

.about-me h5 {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
}

.about-me p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
}

.faq {
  padding: 80px 0px;
  width: 80%;
  margin: auto;
}

.faq h1 {
  font-family: "TradeGothic", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
}

.faq h3 {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  margin-top: 50px;
}

.faq p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
}
.faq img {
  width: 50%;
  margin: auto;
}

.syllabus {
  padding: 50px 120px;
}

.syllabus h5 {
  color: #FF10A2;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  margin-top: 50px;
}

.syllabus h3 {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  margin-top: 0px;
}

.syllabus p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
}

.syllabus img {
  width: 90%;
  margin: auto;
  max-width: 450px;
  margin-bottom: 50px;
}

.testimonial-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 20px;
  padding-left: 60px;
}
.testimonial-wrapper .card {
  display: inline-block;
  margin-right: 30px;
  width: 400px;
  height: 310px;
  background: #f8f8f8;
  opacity: 0.9;
  border-radius: 10px;
  padding: 45px;
}

.testimonial-wrapper .card h3 {
  white-space: normal;
  font-size: 21px;
  line-height: 24px;
  margin-top: 0px;
  min-height: 170px;
  font-weight: 300;
}

.testimonial-wrapper .card h3.purple {
  color: #FF10A2;
}

.testimonial-wrapper .card p {
  margin-bottom: 0px;
}

.pattern-checks-sm {
  height: 20px;
  color: #FF10A2;
}

@media only screen and (max-width: 800px) {
  .hero-container {
    text-align: center;
  }
  .Home .hero .tagline {
    font-size: 21px;
  }
  .intro h1 {
    padding-top: 50px;
    font-size: 28px;
  }
  .intro p {
    margin-bottom: 0px;
  }
  .syllabus {
    padding: 20px;
    text-align: center;
  }
  .syllabus h5 {
    margin-top: 20px;
  }
  .remote-intro {
    text-align: center;
    padding: 0px;
    width: 85%;
    margin: auto;
    float: none;
    padding-top: 30px;
  }
  .remote h3 {
    font-size: 30px;
  }
  .remote {
    padding-bottom: 50px;
  }
  .about-me {
    float: none;
    position: relative;
    padding: 50px 30px;
  }
  .learning img {
    margin-top: 50px;
  }
  .faq {
    padding: 50px 0px;

    width: 95%;
    margin: auto;
    text-align: center;
  }
  .faq h1 {
    font-size: 28px;
  }
}

.Accounts {
}

.Accounts h1 {
  font-size: 28px;
  line-height: 31px;
  color: #0b2e53;
}

.Accounts h3 {
  font-size: 18px;
  line-height: 33px;
  color: #0b2e53;
  margin: 0px;
  margin-bottom: 20px;
}

video {
  width: 100%;
}
