.Conversations {
}

.Conversations h1 {
  font-size: 28px;
  line-height: 31px;
  color: #0b2e53;
  margin-top: 0px;
  margin-bottom: 0px;
}

.Conversations p {
  opacity: 1;
}

.Conversations ul {
  position: relative;
  list-style-type: none;
  padding-left: 0px;
  margin-top: 40px;
}

.Conversations li::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #FF10A2;
  transform: translateX(-50%);
}

.Conversations li {
  list-style: none;
  width: 100%;
  position: relative;
  border-left: 1px solid #FF10A2;
  padding-left: 1.5rem;
  min-height: auto;
  padding-bottom: 2.5rem;
}

.Conversations li p {
  position: relative;
  bottom: 8px;
  white-space: pre-line;
}

.Conversations .highlight {
  color: #FF10A2 !important;
  font-weight: 600;
  opacity: 1;
}

.marker {
  color: white !important;
  background-color: #FF10A2;
  width: 20px;
  border-radius: 20px;
  text-align: center;
  font-size: 11px;
  height: 20px;
  line-height: 20px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.convos-container {
  height: 600px;
  overflow-y: scroll;
  border: 1px solid #ddd;
  padding-left: 0px;
}

.convos-container h4 {
  margin: 0px;
}

.convos-container p {
  font-size: 14px;
  padding: 5px;
}

.contact-img {
  height: 100px !important;
  width: 100px;
  border-radius: 100px;
}

.step {
  padding: 0px 10px;
  margin: 0px;
  cursor: pointer;
  padding-right: 0px;
}

.step h4.POSITIVE {
  border-right: 3px solid #2ecc71;
}

.step h4.none {
  border-right: 3px solid #2ecc71;
}

.step h4.NEUTRAL {
  border-right: 3px solid #f1c40f;
}

.step h4.NEGATIVE {
  border-right: 3px solid #e74c3c;
}

.step:hover {
  background-color: #f8fcff;
  transition: 0.5s all ease;
}

.step.active {
  background-color: #f8fcff;
  transition: 0.5s all ease;
  border-width: 1px;
}

.contacts-container {
  border-right: 1px solid #bdc3c7;
  height: 585px;
  overflow: scroll;
}

.Conversations p {
  white-space: pre-wrap;
}

.conversations-inbox {
  border-top: 1px solid #bdc3c7;
  height: 542px;
  overflow: scroll;
  margin-right: 30px;
  padding-bottom: 100px;
  box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.4);
  position: relative;
  width: 103.6%;
  right: 30px;
  padding: 33px;
  padding-top: 0px;
  padding-bottom: 75px;
}

.Conversations .other-btn {
  float: right;
  margin-right: 30px;
}

.contact-title {
  padding: 20px 0px;
}

.Conversations small {
  color: #2f5687;
  font-size: 11px;
}

.inbox-container {
  width: 73%;
  height: 95px;
  display: flex;
  position: fixed;
  bottom: 0px;
  right: 25px;
  background-color: #fff;
  padding-top: 20px;
}

.inbox-border {
  border-radius: 3px;
  padding-left: 10px;
  width: 99.4%;
}

.inbox-border .other-btn {
  margin: 0;
  top: 0px;
  height: 46px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.inbox-border input {
  width: 100%;
  padding: 10px;
}

.inbox-border.other-btn:hover {
  text-decoration: none !important;
  color: #fff;
  transform: none !important;
  box-shadow: none !important;
}

/* border: solid 2px rgba(158, 171, 179, 0.18); */

.contact-title .NEGATIVE {
  background-color: #fff;
  color: #e74c3c;
  border: 1px solid #e74c3c;
}

.contact-title .NEGATIVE:hover {
  transition: 0.5s all ease;
  background-color: #e74c3c;
  color: white;
}

.contact-title .NEUTRAL {
  background-color: #fff;
  color: #f1c40f;
  border: 1px solid #f1c40f;
}

.contact-title .NEUTRAL:hover {
  transition: 0.5s all ease;
  background-color: #f1c40f;
  color: white;
}

.contact-title .POSITIVE {
  background-color: #fff;
  color: #2ecc71;
  border: 1px solid #2ecc71;
}

.contact-title .POSITIVE:hover {
  transition: 0.5s all ease;
  background-color: #2ecc71;
  color: white;
}

.contact-title .ispositive {
  transition: 0.5s all ease;
  background-color: #2ecc71;
  color: white;
  cursor: default !important;
  border: 1px solid #2ecc71;
}

.contact-title span.NEGATIVE {
  background-color: #e74c3c;
  color: white;
  border: 1px solid #e74c3c;
  cursor: default !important;
}

.contact-title span.NEUTRAL {
  background-color: #f1c40f;
  color: white;
  border: 1px solid #f1c40f;
  cursor: default !important;
}

.progressBarOuter {
  width: 100%;
  position: absolute;
  right: 0px;
  text-align: left;
  border-bottom: 5px solid #FF10A2;
  border-top: 5px solid #FF10A2;
}

.progressBarInner {
  background-color: #FF10A2;
  float: right;
  transition: 4s all ease;
}

.suggested-replies {
  padding-bottom: 40px;
}

.suggested-replies {
  width: 72%;
  height: 95px;
  display: flex;
  position: fixed;
  bottom: 55px;
  right: 25px;
  background-color: #fff;
  padding-top: 20px;
}

.reply-btn {
  border: 1px solid #FF10A2;
  border-radius: 100px;
  padding: 5px 15px;
  background-color: #fff;
}

.reply-btn:hover {
  background-color: #f8fcff;
  color: inherit;
}

.new-suggestion {
  color: white;
  background-color: #FF10A2;
}

.new-suggestion:hover {
  background-color: #FF10A2;
  color: white;
}
