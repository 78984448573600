.NewTemplate {
  padding: 20px;
}

.NewTemplate p {
  text-align: left;
  white-space: pre-line;
}

.NewTemplate .control-label {
  margin: 0;
  margin-bottom: 5px;
}

.NewTemplate .other-btn {
  margin-top: 10px;
}

.NewTemplate .form-group {
  margin-bottom: 15px;
}

.NewTemplate h5 {
  margin-bottom: 20px;
  margin-top: 70px;
}

.NewTemplate .responseScore {
  padding: 5px 10px;
  font-weight: bold;
  font-size: 12px;
  outline: none;
  color: white;
  border: 1px #dadada solid;
  margin-top: 10px;
  border-radius: 2px;
}
